/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('help', function (http, toast) {
    const url = config.apiURL + 'help/';
    function errorHandler(err) {
        if (err) {
            if (err.message) {
                if (err.type !== 'HTTPService') {
                    return Promise.reject(err.message);
                } else {
                    toast.show(err.message, 'error', true);
                }
            } else {
                if (err.data && err.data.message) {
                    return Promise.reject(err.data);
                }
            }
            return Promise.reject(err);
        }
        return Promise.reject();
    }
    return {
        get: (type, id = null, filter = false) => {
            let _url = url + (type === 'sliders' ? 'sliders/' : '');
            if (filter) {
                _url += '?filter[type]=' + id;
            } else {
                _url += id !== null ? id : '';
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    resolve(res.data);
                }).catch(errorHandler);
            });
        },
        addSlide: (item, contentType = undefined) => {
            return http.post(url + 'sliders', item, {
                headers: {'Content-Type': contentType}
            }).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        upload: (item) => {
            return http.post(url, item, {
                headers: {'Content-Type': undefined}
            }).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        update: (item) => {
            return http.put(url + 'sliders/' + item._id, item).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        delete: (item) => {
            
            console.log('delete slide');
            console.log(item);
            
            return http.delete(url + 'sliders/' + (item._id ? item._id : item)).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        }
    };
});

